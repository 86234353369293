<template>
  <Carousel
    :value="banners"
    :circular="smallerThanLg"
    :autoplayInterval="3000"
    :numVisible="2"
    :numScroll="2"
    :showNavigators="false"
    :responsiveOptions="responsiveOptions"
  >
    <template #item="slotProps">
      <div class="mx-3">
        <img :src="slotProps.data" alt="" />
      </div>
    </template>
  </Carousel>
</template>

<script setup>
import banner1 from "@/assets/img/hloan-banner-2.png";
import banner2 from "@/assets/img/hloan-banner.png";

import { ref } from "vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";

const banners = [banner1, banner2];
const breakpoints = useBreakpoints(breakpointsTailwind);

const smallerThanLg = breakpoints.smaller("sm");

const responsiveOptions = ref([
  {
    breakpoint: "1400px",
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: "1199px",
    numVisible: 3,
    numScroll: 1,
  },
  {
    breakpoint: "767px",
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: "575px",
    numVisible: 1,
    numScroll: 1,
  },
]);
</script>

<style></style>
