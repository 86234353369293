<template>
  <Sidebar
    v-model:visible="toggle"
    :dismissable="false"
    position="right"
    class="relative w-full sm:max-w-md"
  >
    <template #container>
      <easiRoundButton
        class="absolute top-4 right-4 z-10"
        @click="handleClose"
      />
      <div v-if="!startApplication" class="">
        <div
          style="box-shadow: 0px 9px 18px 0px #aaaaaa26"
          class="p-4 md:p-6 flex flex-col gap-5 absolute top-0 left-0 w-full bg-white"
        >
          <h4 class="text-center font-semibold mb-5">{{ title }}</h4>

          <LoanAmountCard plain title="Loan amount <br/> payable" />

          <easiButton block @click="handleTakeLoan">Take Loan</easiButton>
        </div>

        <div class="p-4 md:p-6 flex flex-col gap-5 font-medium mt-64">
          <h4>Loan breakdown</h4>
          <easiEmptyState
            title="No Breakdown!"
            caption="You have not taken any loan yet. 
        Click the button above to get a quick loan."
          />
        </div>
      </div>
      <div v-else class="w-full h-full overflow-auto">
        <Operational
          v-if="loanType == 'Operational_Loan'"
          @goBack="startApplication = false"
          @close="handleClose"
        />
        <Payroll
          v-if="loanType == 'Payroll_Cover'"
          @goBack="startApplication = false"
          @close="handleClose"
        />
        <Business
          v-if="loanType == 'Small_Business_Loan'"
          @goBack="startApplication = false"
          @close="handleClose"
        />
      </div>
    </template>
  </Sidebar>

  <easiRoundLoader v-if="loading" text="Confirming <br/> Eligibility " />
</template>

<script setup>
import LoanAmountCard from "./LoanAmountCard.vue";

import Operational from "./Operational/Index.vue";
import Payroll from "./Payroll/Index.vue";
import Business from "./Business/Index.vue";

import { useRoute } from "vue-router";
import { ref, computed } from "vue";

const route = useRoute();

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["close"]);

const toggle = computed({
  get() {
    return props.isOpen;
  },
  set(val) {
    emit("close");
  },
});

const loanType = computed(() => route.query.type || "Title");
const title = computed(() => {
  return loanType.value.split("_").join(" ");
});

const loading = ref(false);
const startApplication = ref(false);

const handleClose = () => {
  startApplication.value = false;
  emit("close");
};

const handleTakeLoan = async () => {
  loading.value = true;
  setTimeout(() => {
    loading.value = false;
    startApplication.value = true;
  }, 3000);
};
</script>

<style></style>
