<template>
  <div
    v-if="data"
    :class="[
      isNextInstallment
        ? 'border-dashed border-primary bg-newLimeGreen'
        : 'border-newGrey3 bg-landingGrey',
    ]"
    class="relative grid grid-cols-1 gap-2 rounded-2xl border p-4"
  >
    <div class="flex items-center justify-between gap-2 text-dark-800">
      <h3 class="line-clamp-1 text-base font-semibold">
        {{ checkNull(data.description) }}
      </h3>
      <span
        :class="[
          data.status && data.status.toLowerCase() == 'unpaid'
            ? 'text-error'
            : 'text-success',
        ]"
        class="rounded-full bg-white px-3 py-1 text-center text-sm capitalize"
      >
        {{ (data.status && data.status.toLowerCase()) || "N/A" }}</span
      >
    </div>

    <div class="grid grid-cols-2 gap-2">
      <div
        v-for="(item, index) in infoList"
        :key="item.title"
        :class="[index % 2 == 1 ? 'text-right' : 'text-left']"
        class="flex flex-col text-sm"
      >
        <span class="text-xs text-dark-600">{{ item.title }}</span>
        <span>{{ item.value }}</span>
      </div>
    </div>

    <div v-if="$slots.default">
      <slot />
    </div>

    <div
      style="
        left: 50%;
        transform: translateX(-50%);
        border-left: 3px dashed #171717;
        border-right: 3px dashed #171717;
      "
      v-if="showLine"
      class="absolute -bottom-8 h-8 w-10/12"
    ></div>
  </div>
</template>

<script setup>
import { helperFunctions } from "@/composable/helperFunctions";
const { formatAmount, formatDate, checkNull } = helperFunctions;
import { computed, ref, watch } from "vue";

const props = defineProps({
  data: Object,

  dataKey: {
    type: String,
    default: "_id",
  },

  nextLoanInstallment: Object,
  showDate: {
    type: Boolean,
    default: true,
  },
  showLine: {
    type: Boolean,
    default: true,
  },
});

const watchNextLoanInstallment = computed(() => props.nextLoanInstallment);
const isNextInstallment = ref(false);
watch(
  watchNextLoanInstallment,
  () => {
    if (!props.nextLoanInstallment) return;

    isNextInstallment.value =
      props.nextLoanInstallment[props.dataKey] === props.data[props.dataKey];
  },
  { immediate: true }
);

const infoList = computed(() => {
  const { data, showDate } = props;
  const others = data.others || [];
  const arr = [
    {
      title: "Due date:",
      value: formatDate(data.date || data.dueDate, "DD/MM/YYYY"),
      show: showDate,
    },
    {
      title: "Amount:",
      value: formatAmount(data.amountDue),
      show: showDate,
    },
    {
      title: "Penal fee:",
      value: formatAmount(data.penalty),
      show: !!data.penalty,
    },
    {
      title: "Total Amount due:",
      value: formatAmount(data.totalAmountDue),
      show: !!data.penalty,
    },
    ...others,
  ];

  return arr.filter((el) => el.show);
});
</script>

<style></style>
