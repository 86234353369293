<template>
  <div v-if="data" class="grid grid-cols-1 gap-6">
    <div>
      <h4 class="text-sm text-dark-800 mb-2 font-medium">
        Upload 6 months bank statement of active account
      </h4>
      <UploadComponent
        classic
        @fileUrl="data.bankStatement = $event"
        :allowedTypes="['pdf']"
        label="2mb Max"
        :size="2000000"
        :error="error.bankStatement"
      />
    </div>

    <div>
      <h4 class="text-sm text-dark-800 mb-2 font-medium">
        Upload audited financial statements
      </h4>
      <UploadComponent
        @fileUrl="data.financialStatement = $event"
        classic
        :allowedTypes="['pdf']"
        label="2mb Max"
        :size="2000000"
        :error="error.financialStatement"
      />
      <p class="text-xs text-dark-700 leading-4 mt-1">
        (e.g. Income statement, balance sheet, etc.)
      </p>
    </div>
    <div>
      <h4 class="text-sm text-dark-800 mb-2 font-medium">
        Upload collateral/security cover
      </h4>
      <UploadComponent
        @fileUrl="data.collateral = $event"
        classic
        :allowedTypes="['pdf']"
        label="2mb Max"
        :size="2000000"
        :error="error.collateral"
      />
      <p class="text-xs text-dark-700 leading-4 mt-1">
        (e.g. receipt of assets, C of O of property, personal Guarantor letter
        from the CEO of a Company, etc.)
      </p>
    </div>
    <div class="grid grid-cols-2 gap-3">
      <easiButton
        @click="emit('back')"
        variant="text"
        background="#11453B1A"
        block
      >
        <div class="flex items-center gap-2">
          <i class="pi pi-arrow-left" />
          Back
        </div>
      </easiButton>
      <easiButton @click="emit('preview')" block> Preview Loan </easiButton>
    </div>
  </div>
</template>

<script setup>
import UploadComponent from "@/components/global/UploadComponent";

const props = defineProps({
  data: Object,
  error: Object,
});

const emit = defineEmits(["back", "preview"]);
</script>

<style></style>
