<template>
  <div
    :style="`background: ${isOpen ? 'rgba(0, 19, 9, 0.36)' : 'transparent'}`"
    :class="[isOpen ? '' : 'pointer-events-none']"
    class="absolute inset-0 bg-gray-50 flex flex-col justify-end z-30"
  >
    <div
      :style="`${isOpen ? 'height: 80%;' : 'height: 0px'}`"
      class="flex flex-col trans gap-5 pt-6 relative bg-white rounded-t-3xl transition-all duration-300"
    >
      <h3 class="mb-6 font-medium text-center">Loan repayment breakdown</h3>
      <easiRoundButton @click="emit('close')" class="absolute top-4 right-4" />

      <div
        style="max-height: 600px"
        class="h-full pb-8 px-4 overflow-auto no-scrollbar grid grid-cols-1 gap-4"
      >
        <BreakdownCard
          v-for="item in 8"
          :key="item"
          :data="{}"
          :showLine="item !== 8"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import BreakdownCard from "./BreakdownCard.vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["close"]);
</script>

<style></style>
