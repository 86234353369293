<template>
  <div
    :style="`background-color: ${plain ? '#F8F8F8' : '#D9EBCD'}`"
    :class="[rounded]"
    class="p-3 pl-3.5 flex items-center justify-between"
  >
    <span
      :class="[!small ? 'font-semibold' : '']"
      class="text-xs sm:text-sm leading-4"
      v-html="title"
    />

    <span
      :class="[plain ? '' : 'text-primary', small ? 'text-lg' : 'text-xl']"
      style="box-shadow: 0px 6px 12px 0px #aaaaaa1c"
      class="font-bold p-2.5 bg-white rounded-xl"
    >
      {{ formatAmount(value || 0) }}
    </span>
  </div>
</template>

<script setup>
import { helperFunctions } from "@/composable/helperFunctions";
const { formatAmount } = helperFunctions;

const props = defineProps({
  plain: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: String,
    default: "rounded-2xl",
  },
  small: Boolean,
  title: String,
  value: [String, Number],
});
</script>

<style></style>
