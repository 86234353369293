<template>
  <div
    @click="emit('click')"
    :class="[
      active
        ? 'border-primary bg-background'
        : 'bg-newGrey3 border-outlineGray',
    ]"
    class="cursor-pointer p-4 rounded-2xl border relative overflow-hidden"
  >
    <p class="text-xs sm:text-sm w-11/12" v-html="text"></p>

    <img
      v-if="active"
      class="absolute top-3 right-3"
      src="@/assets/icons/primary-check.svg"
      alt=""
    />
  </div>
</template>

<script setup>
const props = defineProps({
  text: String,
  active: Boolean,
});

const emit = defineEmits(["click"]);
</script>

<style></style>
