<template>
  <easiRoundButton @click="handleGoBack" class="absolute z-20 top-4 left-4">
    <i class="pi pi-chevron-left text-sm" />
  </easiRoundButton>

  <div v-if="!isPreviewLoan" class="p-4 md:p-6 flex flex-col gap-5">
    <h4 class="text-center font-semibold mb-4">Get operational loan</h4>
    <div class="mt-4">
      <h2 class="font-semibold mb-1">
        {{ progress === 1 ? "Loan Information" : "Upload relevant documents" }}
      </h2>
      <LoanProgress :progress="progress" :length="2" />
    </div>
    <LoanInfo
      v-if="progress === 1"
      :data="args"
      :error="errorRules"
      @cancel="handleClose"
      @proceed="handleProceed"
    />

    <RelevantDocument
      v-if="progress === 2"
      :data="args"
      :error="errorRules"
      @back="progress = 1"
      @preview="handlePreview"
    />
  </div>

  <PreviewLoan
    v-else
    :isOpen="isPreviewLoan"
    @submit="submit"
    :data="previewData"
    type="operational"
  />
</template>

<script setup>
import LoanProgress from "../LoanProgress.vue";
import LoanInfo from "./LoanInfo.vue";
import RelevantDocument from "./RelevantDocument.vue";
import PreviewLoan from "../PreviewLoan.vue";

import { computed, ref, reactive } from "vue";

const emit = defineEmits(["close", "goBack"]);

const loading = ref(false);
const progress = ref(1);

const previewData = ref(null);
const isPreviewLoan = ref(false);
const isAlert = ref(false);

const args = ref({
  amount: "",
  duration: "",
  paymentType: "",
  purpose: "",
  bankStatement: null,
  collateral: null,
  financialStatement: null,
});

const errorRules = reactive({
  amount: false,
  duration: false,
  paymentType: false,
  purpose: false,
  bankStatement: false,
  collateral: false,
  financialStatement: false,
});

const validateFields = () => {
  resetError();

  if (!args.value.amount) {
    errorRules.amount = "Amount is required";
    return false;
  } else if (!args.value.duration) {
    errorRules.duration = "Duration  is required";
    return false;
  } else if (!args.value.paymentType) {
    errorRules.paymentType = "Repayment type is required";
    return false;
  } else if (!args.value.bankStatement && progress.value === 2) {
    errorRules.bankStatement = "Bank statement  is required";
    return false;
  } else if (!args.value.financialStatement && progress.value === 2) {
    errorRules.financialStatement = "Audited financial statement  is required";
    return false;
  } else if (!args.value.collateral && progress.value === 2) {
    errorRules.collateral = "Collateral/Security cover  is required";
    return false;
  }

  resetError();
  return true;
};

function resetError() {
  Object.keys(errorRules).map((key) => {
    errorRules[key] = false;
  });
}

function clearFields() {
  Object.keys(args.value).map((key) => {
    args.value[key] = "";
  });
}

function handleClose() {
  isPreviewLoan.value = false;
  emit("close");
  clearFields();
  resetError();
  progress.value = 1;
}

function handleGoBack() {
  if (isPreviewLoan.value) return (isPreviewLoan.value = false);
  if ((progress.value = 2)) return (progress.value = 1);
  emit("goBack");
}

const handleProceed = () => {
  if (!validateFields()) return;
  progress.value = 2;
};

const handlePreview = () => {
  if (!validateFields()) return;
  previewData.value = { ...args.value };
  isPreviewLoan.value = true;
};

const submit = async () => {
  loading.value = true;
  setTimeout(() => {
    handleClose();
    isAlert.value = true;
    loading.value = false;
  }, 3000);
};
</script>

<style></style>
