<template>
  <div
    v-if="data"
    style="box-shadow: 0px 4px 8px 0px rgba(170, 170, 170, 0.08)"
    @click="emit('click')"
    class="cursor-pointer relative grid grid-cols-1 gap-2.5 overflow-hidden rounded-3xl border border-outline px-5 p-6"
  >
    <div class="flex items-center justify-between gap-4">
      <div class="flex items-center gap-4">
        <div class="rounded-xl">
          <img :src="data.icon" alt="" />
        </div>
        <h3 class="text-base font-semibold">
          {{ checkNull(data.title) }}
        </h3>
      </div>
      <img src="@/assets/icons/chevron-right-dark.svg" alt="" />
    </div>
    <p class="text-sm text-dark-800">
      {{ checkNull(data.description) }}
    </p>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
const { checkNull } = helperFunctions;

const props = defineProps({
  data: Object,

  type: {
    type: String,
    default: "personal",
  },
});

const emit = defineEmits(["click"]);
</script>

<style></style>
